import { useState } from 'react';
import { MdOutlineLocationOn, MdPhoneIphone } from 'react-icons/md';
import { Address } from 'shared/types/account';
import FormattedAddressSummary from '~/components/furniturechoice/formatted-address-summary';
import SideModal from '~/components/furniturechoice/side-modal';
import { useAccount } from '~/frontastic/hooks';
import { useFormat } from '~/helpers/hooks/useFormat';
import Form from './form';

interface BillingAddressProps {
  accountManager: ReturnType<typeof useAccount>;
  address: Address;
}

export default function BillingAddress({ accountManager, address }: BillingAddressProps): JSX.Element {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      <section className="flex max-w-[487px] flex-col gap-y-3">
        <h2 className="text-center font-sans text-20 font-bold lg:text-left">
          {formatMessage({ id: 'tastics.account.details-content-manager.billing_address' })}
        </h2>

        <div className="surface-grey-2 flex flex-col gap-y-3 rounded-md px-4 py-5">
          <div className="flex items-center justify-between gap-x-2">
            <p className="font-bold" data-testid="name">
              {`${address?.salutation} ${address?.firstName} ${address?.lastName}`}
            </p>

            <button className="text-12 text-grey-7" onClick={() => setIsFormVisible(true)}>
              {formatMessage({ id: 'tastics.account.details-content-manager.edit' })}
            </button>
          </div>

          <FormattedAddressSummary address={address} icon={<MdOutlineLocationOn />} />

          <div className="flex items-center gap-x-2">
            <MdPhoneIphone className="text-20 text-grey-5" />

            <div className="flex flex-col gap-y-2">
              <p className="text-grey-5">{address.phone}</p>
            </div>
          </div>
        </div>
      </section>

      <SideModal
        isOpen={isFormVisible}
        title={formatMessage({ id: 'tastics.account.details-content-manager.edit_billing_address' })}
        titleAs="h3"
        onModalClose={() => setIsFormVisible(false)}
      >
        <Form accountManager={accountManager} address={address} onSubmitSuccess={() => setIsFormVisible(false)} />
      </SideModal>
    </>
  );
}
