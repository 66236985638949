import { HTMLAttributes, useState } from 'react';
import { Address } from 'shared/types/account';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import FNCSelect from '~/components/furniturechoice/fnc/fnc-select';
import { useFormat } from '~/helpers/hooks/useFormat';
import { billingCountries, defaultBillingCountry } from '~/static/billing-countries';

interface AddressFormContentProps extends HTMLAttributes<HTMLDivElement> {
  type: 'shipping' | 'billing';
  defaultAddress?: Address;
  defaultEmail?: string;
  defaultSeparateShippingContactNumber?: string;
}

export default function AddressFormContent({
  type,
  defaultAddress,
  defaultEmail,
  defaultSeparateShippingContactNumber,
}: AddressFormContentProps): JSX.Element {
  const [hasSeparateShippingContactNumber, setHasSeparateShippingContactNumber] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(!!defaultAddress);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="flex flex-col gap-y-3 rounded-md" data-testid={`${type}-address`}>
      <fieldset>
        <div className="surface-white relative flex min-h-[63px] flex-col gap-y-1 rounded-md border border-solid border-grey-7">
          <legend className="absolute left-3 top-2 text-12 text-grey-7">
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.salutation' })}
          </legend>

          <div className="flex flex-wrap items-center gap-4 px-4 pb-4 pt-8">
            <FNCRadio
              data-testid="miss-salutation-field"
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.miss' })}
              name={`${type}.salutation`}
              value="Miss"
              required
              defaultChecked={defaultAddress?.salutation === 'Miss'}
            />

            <FNCRadio
              data-testid="mr-salutation-field"
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.mr' })}
              name={`${type}.salutation`}
              value="Mr."
              required
              defaultChecked={defaultAddress?.salutation === 'Mr.'}
            />

            <FNCRadio
              data-testid="mrs-salutation-field"
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.mrs' })}
              name={`${type}.salutation`}
              value="Mrs."
              required
              defaultChecked={defaultAddress?.salutation === 'Mrs.'}
            />

            <FNCRadio
              data-testid="ms-salutation-field"
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.ms' })}
              name={`${type}.salutation`}
              value="Ms."
              required
              defaultChecked={defaultAddress?.salutation === 'Ms.'}
            />
          </div>
        </div>
      </fieldset>

      <FNCInput
        data-testid="first-name-field"
        type="text"
        name={`${type}.firstName`}
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.first_name' })}
        defaultValue={defaultAddress?.firstName}
        required
      />

      <FNCInput
        data-testid="last-name-field"
        type="text"
        name={`${type}.lastName`}
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.last_name' })}
        defaultValue={defaultAddress?.lastName}
        required
      />

      {defaultEmail !== undefined && (
        <FNCInput
          data-testid="email-field"
          type="email"
          name={`${type}.email`}
          label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.email' })}
          defaultValue={defaultEmail}
          required
        />
      )}

      <FNCInput
        data-testid="phone-field"
        type="tel"
        name={`${type}.phone`}
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.contact_number' })}
        defaultValue={defaultAddress?.phone}
        required
      />

      {defaultSeparateShippingContactNumber !== undefined && (
        <>
          {hasSeparateShippingContactNumber && (
            <FNCInput
              data-testid="shipping-contact-number-field"
              type="tel"
              name="shipping.phone"
              label={formatMessage({
                id: 'tastics.checkout.information.information-content-manager.delivery_contact_number',
              })}
              defaultValue={defaultSeparateShippingContactNumber}
            />
          )}

          {!hasSeparateShippingContactNumber && (
            <button
              className="mt-1 text-12 text-grey-7"
              data-testid="separate-shipping-contact-number-cta-button"
              type="button"
              onClick={() => setHasSeparateShippingContactNumber(true)}
            >
              {`${formatMessage({
                id: 'tastics.checkout.information.information-content-manager.add_a_different_number_for_delivery',
              })} >`}
            </button>
          )}
        </>
      )}

      {isExpanded && (
        <>
          <FNCInput
            data-testid="house-name-field"
            type="text"
            name={`${type}.streetNumber`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.house_name' })}
            defaultValue={defaultAddress?.streetNumber}
          />

          <FNCInput
            data-testid="street-field"
            type="text"
            name={`${type}.streetName`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.street' })}
            defaultValue={defaultAddress?.streetName}
            required
          />

          <FNCInput
            data-testid="locality-field"
            type="text"
            name={`${type}.additionalAddressInfo`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.locality' })}
            defaultValue={defaultAddress?.additionalAddressInfo}
          />

          <FNCInput
            data-testid="town-or-city-field"
            type="text"
            name={`${type}.city`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.town_or_city' })}
            defaultValue={defaultAddress?.city}
            required
          />

          <FNCInput
            data-testid="county-field"
            type="text"
            name={`${type}.region`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.county' })}
            defaultValue={defaultAddress?.region}
          />

          <FNCSelect
            data-testid="country-field"
            name={`${type}.country`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.country' })}
            disabled={type === 'shipping'}
            defaultValue={defaultBillingCountry}
          >
            {Object.keys(billingCountries).map((key) => (
              <option key={key} value={key}>
                {billingCountries[key as keyof typeof billingCountries]}
              </option>
            ))}
          </FNCSelect>

          {/* Need this because <FNCSelect> for country is disabled on shipping address */}
          {/* When a field is disabled, it doesn't get to be part of the form payload */}
          {type === 'shipping' && <input type="hidden" name={`${type}.country`} defaultValue={defaultBillingCountry} />}
        </>
      )}

      <FNCInput
        data-testid="postal-code-field"
        type="text"
        name={`${type}.postalCode`}
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.postcode' })}
        defaultValue={defaultAddress?.postalCode}
        required
      />

      {isExpanded && (
        <button
          className="mt-1 text-12 font-bold text-grey-4 lg:ml-2 lg:self-start"
          data-testid="reset-address-button"
          type="reset"
          onClick={() => setIsExpanded(false)}
        >
          {formatMessage({ id: 'tastics.checkout.information.information-content-manager.reset_address' })}
        </button>
      )}

      {!isExpanded && (
        <>
          <FNCButton className="surface-green-2 rounded-sm" data-testid="find-address-button" type="button">
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.find_address' })}
          </FNCButton>

          <button
            className="mt-1 text-12 text-grey-7"
            data-testid="enter-address-button"
            type="button"
            onClick={() => setIsExpanded(true)}
          >
            {`${formatMessage({ id: 'tastics.checkout.information.information-content-manager.enter_address' })} >`}
          </button>
        </>
      )}
    </div>
  );
}
