import { useState } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Address } from 'shared/types/account';
import SideModal from '~/components/furniturechoice/side-modal';
import { useAccount } from '~/frontastic/hooks';
import { useFormat } from '~/helpers/hooks/useFormat';
import AddressCard from './address-card';
import Form from './form';

interface ShippingAddressesProps {
  accountManager: ReturnType<typeof useAccount>;
  addresses: Address[];
}

export default function ShippingAddresses({ accountManager, addresses }: ShippingAddressesProps): JSX.Element {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <section className="flex max-w-[487px] flex-col gap-y-3">
      <h2 className="text-center font-sans text-20 lg:text-left">
        <span className="font-bold">
          {formatMessage({ id: 'tastics.account.details-content-manager.delivery_address' })}
        </span>{' '}
        <span>({addresses.length})</span>
      </h2>

      {addresses.map((address) => (
        <AddressCard key={address.addressId} accountManager={accountManager} address={address} />
      ))}

      <button
        className="surface-grey-1 flex items-center gap-x-2 rounded-md p-4 text-grey-5"
        onClick={() => setIsFormVisible(true)}
      >
        <MdAddCircleOutline className="text-20" />
        {formatMessage({ id: 'tastics.account.details-content-manager.add_a_new_delivery_address' })}
      </button>

      <SideModal
        isOpen={isFormVisible}
        title={formatMessage({ id: 'tastics.account.details-content-manager.add_delivery_address' })}
        titleAs="h3"
        onModalClose={() => setIsFormVisible(false)}
      >
        <Form accountManager={accountManager} onSubmitSuccess={() => setIsFormVisible(false)} />
      </SideModal>
    </section>
  );
}
