import { HTMLAttributes } from 'react';
import { Account } from 'shared/types/account';
import Header from './header';
import Navigation from './navigation';

interface AccountContainer extends HTMLAttributes<HTMLDivElement> {
  account: Account;
  contentHeadline: string;
  contentHeadlineIcon: JSX.Element;
}

export default function AccountContainer({
  children,
  account,
  contentHeadline,
  contentHeadlineIcon,
  ...props
}: AccountContainer): JSX.Element {
  return (
    <div {...props}>
      <Header account={account} contentHeadline={contentHeadline} contentHeadlineIcon={contentHeadlineIcon} />

      <div className="container lg:grid lg:grid-cols-[min-content_1fr] lg:gap-x-7">
        <Navigation />
        {children}
      </div>
    </div>
  );
}
