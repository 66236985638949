import { BsBoxSeam } from 'react-icons/bs';
import { MdPassword, MdPersonOutline } from 'react-icons/md';
import Link from '~/components/furniturechoice/link';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function Navigation(): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <nav className="hidden pt-3 lg:block">
      <ul className="flex flex-col gap-8">
        <li className="flex-1 lg:border-none">
          <Link
            className="flex w-full items-center gap-x-2 whitespace-nowrap data-[active='true']:text-salmon-1"
            href="/account/orders"
          >
            <BsBoxSeam className="text-24" />
            {formatMessage({ id: 'tastics.account.account-content-manager.my_orders' })}
          </Link>
        </li>

        <li className="flex-1 lg:border-none">
          <Link
            className="flex w-full items-center gap-x-2 whitespace-nowrap data-[active='true']:text-salmon-1"
            href="/account/details"
          >
            <MdPersonOutline className="text-24" />
            {formatMessage({ id: 'tastics.account.account-content-manager.my_details' })}
          </Link>
        </li>

        <li className="flex-1 lg:border-none">
          <Link
            className="flex w-full items-center gap-x-2 whitespace-nowrap data-[active='true']:text-salmon-1"
            href="/account/change-password"
          >
            <MdPassword className="text-24" />
            {formatMessage({ id: 'tastics.account.account-content-manager.change_password' })}
          </Link>
        </li>
      </ul>
    </nav>
  );
}
