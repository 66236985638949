import { MdArrowBack } from 'react-icons/md';
import { Account } from 'shared/types/account';
import AccountWelcomeHeader from '~/components/furniturechoice/account-welcome-header';
import Link from '~/components/furniturechoice/link';

interface HeaderProps {
  account: Account;
  contentHeadline: string;
  contentHeadlineIcon: JSX.Element;
}

export default function Header({ account, contentHeadline, contentHeadlineIcon }: HeaderProps): JSX.Element {
  return (
    <div className="container mb-5 lg:mb-8">
      <header className="border-b border-solid border-grey-3 py-4 lg:hidden">
        <div className="flex items-center">
          <Link href="/account">
            <MdArrowBack className="text-30" />
          </Link>

          <h1 className="flex flex-1 items-center justify-center gap-x-2 pr-[30px] font-sans text-24 font-bold">
            <span className="text-30">{contentHeadlineIcon}</span>
            {contentHeadline}
          </h1>
        </div>
      </header>

      <AccountWelcomeHeader className="hidden lg:flex" account={account} />
    </div>
  );
}
