import { FormEvent, useContext } from 'react';
import { Address } from 'shared/types/account';
import AddressFormContent from '~/components/furniturechoice/address-form-content';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import buildAddressFromForm from '~/helpers/utils/furniturechoice/build-address-from-form';

interface FormProps {
  accountManager: ReturnType<typeof useAccount>;
  address: Address;
  onSubmitSuccess(): void;
}

export default function Form({ accountManager, address, onSubmitSuccess }: FormProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);

    await executeWithProgress(() =>
      accountManager.updateAddress({ ...address, ...buildAddressFromForm(payload, 'billing') }),
    );
    onSubmitSuccess();
  }

  return (
    <form method="post" onSubmit={handleSubmit}>
      <AddressFormContent type="billing" defaultAddress={address} />

      <FNCButton className="surface-green-2 mt-4 w-full rounded-md" type="submit" data-testid="submit-button">
        {formatMessage({ id: 'tastics.account.details-content-manager.save_changes' })}
      </FNCButton>
    </form>
  );
}
