import { Address } from 'shared/types/account';
import { billingCountries } from '~/static/billing-countries';

interface FormattedAddressProps {
  address: Address;
  icon?: JSX.Element;
}

function buildAddress(address: Address): string {
  const keys = ['streetNumber', 'streetName', 'additionalAddressInfo', 'city', 'region', 'postalCode'];
  const values = keys.map((key) => address[key as keyof Address]);

  return [...values, billingCountries[address.country as keyof typeof billingCountries]].filter((i) => i).join('\n');
}

export default function FormattedAddress({ address, icon }: FormattedAddressProps): JSX.Element {
  return (
    <div className="flex gap-x-2">
      {icon && (
        <span className="self-start text-20 text-grey-5" data-testid="icon">
          {icon}
        </span>
      )}

      <div className="flex flex-col gap-y-2">
        <p className="whitespace-pre-line leading-5 text-grey-5" data-testid="address">
          {buildAddress(address as Address)}
        </p>
      </div>
    </div>
  );
}
