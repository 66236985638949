import { useContext } from 'react';
import { MdOutlineDeleteOutline, MdOutlineLocationOn, MdPhoneIphone } from 'react-icons/md';
import { Address } from 'shared/types/account';
import FormattedAddressSummary from '~/components/furniturechoice/formatted-address-summary';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

interface AddressCardProps {
  accountManager: ReturnType<typeof useAccount>;
  address: Address;
}

export default function AddressCard({ accountManager, address }: AddressCardProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  function handleDeleteClick() {
    executeWithProgress(() => accountManager.removeAddress(address.addressId as string));
  }
  return (
    <div key={address.addressId} className="surface-grey-2 flex flex-col gap-y-3 rounded-md px-4 py-5">
      <div className="flex items-center justify-between gap-x-2">
        <p className="font-bold" data-testid="name">
          {`${address?.salutation} ${address?.firstName} ${address?.lastName}`}
        </p>

        <button
          className="text-20 text-grey-5"
          data-testid="delete-button"
          aria-label={formatMessage({ id: 'tastics.account.details-content-manager.delete' })}
          onClick={handleDeleteClick}
        >
          <MdOutlineDeleteOutline />
        </button>
      </div>

      <FormattedAddressSummary address={address} icon={<MdOutlineLocationOn />} />

      <div className="flex items-center gap-x-2">
        <MdPhoneIphone className="text-20 text-grey-5" />

        <div className="flex flex-col gap-y-2">
          <p className="text-grey-5">{address.phone}</p>
        </div>
      </div>
    </div>
  );
}
