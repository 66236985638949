'use client';

import { MdPersonOutline } from 'react-icons/md';
import { Account } from 'shared/types/account';
import AccountContainer from '~/components/furniturechoice/account-container';
import { useAccount } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import BillingAddress from './billing-address';
import ShippingAddresses from './shipping-addresses';

interface DetailsContentManagerProps {
  account: DataSourceV2<Account>;
}

export default function DetailsContentManager({ data: { account } }: TasticProps<DetailsContentManagerProps>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const accountManager = useAccount();
  const accountData = accountManager.account || account.dataSource;
  const billingAddress = accountData.addresses?.find((address) => address.isBillingAddress);
  const shippingAddresses = accountData.addresses?.filter((address) => address.isShippingAddress);

  return (
    <section className="standard-section-padding">
      <AccountContainer
        account={accountData}
        contentHeadline={formatMessage({ id: 'tastics.account.details-content-manager.my_details' })}
        contentHeadlineIcon={<MdPersonOutline />}
      >
        <div className="flex flex-col gap-y-10">
          {billingAddress && <BillingAddress accountManager={accountManager} address={billingAddress} />}
          {shippingAddresses && <ShippingAddresses accountManager={accountManager} addresses={shippingAddresses} />}
        </div>
      </AccountContainer>
    </section>
  );
}
